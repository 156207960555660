import React, { useRef, useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import Modal from 'react-modal';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap'; // Import Table from react-bootstrap
import coverImage from './cover.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import '../index.css';

// Register Handsontable's modules
registerAllModules();

const PAGE_SIZE = 10; // Define page size

const DemoProduct = () => {
  const hotRef = useRef(null);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1); // Current active page
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [editRowData, setEditRowData] = useState(null); // State for edited row data
  const [editModalOpen, setEditModalOpen] = useState(false); // State to control the edit modal

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setColumns(
          result.meta.fields.map((fieldName) => ({
            title: fieldName,
            type: 'text',
            data: fieldName,
          }))
        );
        setTableData(result.data);
        setActivePage(1); // Reset active page to 1 when new data is loaded
        setShowModal(true);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv,.xlsx',
    onDrop: handleFileUpload
  });

  const toggleColumnVisibility = (columnIndex) => {
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.includes(columnIndex)
        ? prevHiddenColumns.filter((index) => index !== columnIndex)
        : [...prevHiddenColumns, columnIndex]
    );
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const buttonClickCallback = () => {
    const hot = hotRef.current.hotInstance;
    const exportPlugin = hot.getPlugin('exportFile');
    exportPlugin.downloadFile('csv', {
        bom: false,
        columnDelimiter: ',',
        columnHeaders: false,
        exportHiddenColumns: true,
        exportHiddenRows: true,
        fileExtension: 'csv',
        filename: 'Handsontable-CSV-file_[YYYY]-[MM]-[DD]',
        mimeType: 'text/csv',
        rowDelimiter: '\r\n',
        rowHeaders: true
      });
  };

  const handleColumnSelect = () => {
    setShowModal(false);
    const selectedColumnsData = columns.filter((col, index) => selectedColumns.includes(index));
    setData(tableData.map(row => {
      return selectedColumnsData.reduce((acc, currCol) => {
        acc[currCol.title] = row[currCol.data];
        return acc;
      }, {});
    }));

    // Hide non-selected columns
    const nonSelectedColumns = columns.filter((col, index) => !selectedColumns.includes(index));
    const nonSelectedColumnIndexes = nonSelectedColumns.map((col) => columns.indexOf(col));
    setHiddenColumns(nonSelectedColumnIndexes);
  };

  const handlePageSelect = (event) => {
    setActivePage(parseInt(event.target.value));
  };

  const renderPageOptions = () => {
    const pageCount = Math.ceil(filteredData.length / PAGE_SIZE);
    const pageOptions = [];
    for (let i = 1; i <= pageCount; i++) {
      pageOptions.push(<option key={i} value={i}>{i}</option>);
    }
    return pageOptions;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setActivePage(1); // Reset active page when search term changes
  };

  const handleEditRow = (rowData) => {
    setEditRowData(rowData);
    setEditModalOpen(true);
  };

  const handleSaveChanges = () => {
    // Implement logic to save changes
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (data !== null) {
      setShowModal(false);
    }
  }, [data]);

  useEffect(() => {
    Modal.setAppElement('body'); // Set app element for modal
  }, []);

  const indexOfLastRow = activePage * PAGE_SIZE;
  const indexOfFirstRow = indexOfLastRow - PAGE_SIZE;
  let filteredData = data ? data.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  ) : [];
  const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div id="body-play">
      <Container fluid>
        <Row>
          <Col md={3} className="sidebar">
            <div className="sidebar-content">
              <Card>
                <Card.Img variant="top" src={coverImage} style={{ width: '250px', margin: 'auto' }} />
                <Card.Body>
                  <h1>Master Data Management</h1>
                  <div className="controls">
                    <Button id="export-file" onClick={buttonClickCallback}>Download CSV</Button>
                  </div>
                  <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', margin: '20px' }}>
                    <input {...getInputProps()} />
                    <p>Drag & drop CSV or Excel file here, or click to select file</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col md={9}>
            <div className="content">
              <Modal isOpen={showModal} onRequestClose={handleModalClose}>
                <h2>Select Columns</h2>
                <Button onClick={() => setSelectedColumns([...Array(columns.length).keys()])}>Select All</Button>
                <Button onClick={() => setSelectedColumns([])}>Deselect All</Button>
                {columns.map((column, index) => (
                  <div key={index}>
                    <label>
                      <input 
                        type="checkbox" 
                        checked={selectedColumns.includes(index)} 
                        onChange={() => {
                          if (selectedColumns.includes(index)) {
                            setSelectedColumns(selectedColumns.filter(colIndex => colIndex !== index));
                          } else {
                            setSelectedColumns([...selectedColumns, index]);
                          }
                        }} 
                      />
                      {column.title}
                    </label>
                  </div>
                ))}
                <Button onClick={handleColumnSelect}>Import Data</Button>
              </Modal>
              <Row className="justify-content-center mt-3">
                <Col md={8}>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ marginBottom: '10px' }}
                  />
                </Col>
              </Row>
              {data && (
                <Row className="justify-content-center mt-3">
                  <Col md={8}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {columns.map((column, index) => (
                            <th key={index}>{column.title}</th>
                          ))}
                          <th>Edit</th> {/* Add a column for Edit button */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {columns.map((column, colIndex) => (
                              <td key={colIndex}>{row[column.data]}</td>
                            ))}
                            <td>
                              <Button onClick={() => handleEditRow(row)}>Edit</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {data && data.length > PAGE_SIZE && (
                      <div className="pagination-container">
                        <select value={activePage} onChange={handlePageSelect}>
                          {renderPageOptions()}
                        </select>
                        <span> of {Math.ceil(filteredData.length / PAGE_SIZE)}</span>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              <Modal isOpen={editModalOpen} onRequestClose={() => setEditModalOpen(false)}>
                <h2>Edit Data</h2>
                <div>
                  <HotTable
                    ref={hotRef}
                    data={editRowData} // Pass the row data to be edited
                    columns={columns}
                    hiddenColumns={{ columns: hiddenColumns, indicators: true, copyPasteEnabled: false }}
                    height="auto"
                    autoWrapRow={true}
                    autoWrapCol={true}
                    manualColumnMove={true}
                    licenseKey="non-commercial-and-evaluation"
                  />
                </div>
                <Button onClick={handleSaveChanges}>Save Changes</Button>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DemoProduct;
