import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-gray-300 py-4 text-center">
            <div className="container mx-auto">
                <p>&copy; 2024 ErosTech Solutions. Alle Rechte vorbehalten.</p>
                <nav className="flex">
                <Link to="/impressum" className="block mt-2 mr-4 text-gray-400 hover:text-white">Impressum</Link>
                <Link to="/privacy" className="block mt-2 text-gray-400 hover:text-white">Datenschutzerklärung</Link>
                </nav>
            </div>
        </footer>
    )
}

export default Footer;