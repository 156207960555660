// Stellen Sie sicher, dass Sie npm install @formspree/react ausführen
// Für weitere Hilfe besuchen Sie https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
function ContactForm() {
  const [state, handleSubmit] = useForm("xbjnzpvn");

  if (state.succeeded) {
    return (
        <div className='flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left'>
            <div id="sucess" className='w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl'>
                <p>Vielen Dank für Ihre Anfrage - Ihre Nachricht wurde erfolgreich übermittelt.<br></br>
Wir werden uns innerhalb der nächsten 2 Werktage mit Ihnen in Verbindung setzen.</p>
<Link to="/">Zurück zur Startseite</Link>
            </div>
        </div>
    );
}
  return (
    <form onSubmit={handleSubmit}>
        <div className='flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left'>
            <div className='w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl'>
                <label htmlFor="full-name">Vollständiger Name</label>
                <input
                    id="full-name"
                    type="text"
                    name="name"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    placeholder="Vor- und Nachname"
                    required
                />
                <label htmlFor="email">Email-Adresse</label>
                <input
                    id="email"
                    type="email"
                    placeholder="Ihre E-Mail"
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                    className="text-red-500 text-sm"
                />
                <label htmlFor="message">Ihre Anfrage</label>
                <textarea
                    id="message"
                    name="message"
                    placeholder="Wie können wir Ihnen helfen?"
                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                    className="text-red-500 text-sm"
                />
                <button type="submit" disabled={state.submitting} className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                    focus:outline-none focus:shadow-outline">
                    Nachricht senden
                </button>
            </div>
        </div>
    </form>
);
}

function App() {
  return (
    <>
    <div>
        <NavBar />
    </div>
    <div className="App">
      <h1>Kontaktformular</h1>
      <ContactForm />
    </div>
    <Footer />
    </>
  );
}

export default App;