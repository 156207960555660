import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/undraw10.svg';
import img4 from '../images/undraw11.svg';
import img5 from '../images/undraw12.svg';
const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Unser Portfolio</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Durch durchdachte Strategien gestalten wir gemeinsam den Weg für nachhaltiges Wachstum und anhaltenden Erfolg.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                    <p className="text-md font-medium">
                                    Wir entwickeln mobilfreundliche und benutzerfreundliche Websites und Anwendungen, die maßgeschneidert auf die Bedürfnisse unserer Kunden zugeschnitten sind. Mit einem Fokus auf Qualität und Benutzerfreundlichkeit sind wir Ihr zuverlässiger Partner für digitale Lösungen, die Ihr Unternehmen erfolgreich repräsentieren und voranbringen.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">API Development </h2>
                                    <p className="text-md font-medium">
                                    Von der Anbindung bestehender Systeme bis zur Entwicklung neuer Funktionen – wir gestalten Schnittstellen, die Ihr Unternehmen effizienter und agiler machen. Verlassen Sie sich auf uns, um die Synergien zwischen Ihren Anwendungen zu maximieren und eine nahtlose digitale Erfahrung zu schaffen.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">E-Commerce</h2>
                                    <p className="text-md font-medium">
                                    Von der Einrichtung Ihres Online-Shops über das Anlegen von Produkten bis zur SEO-Optimierung – wir bieten Ihnen maßgeschneiderte Lösungen. Darüber hinaus erweitern wir Ihr System durch eigene Apps, um die Funktionalität zu maximieren und eine individuelle, leistungsstarke E-Commerce-Plattform zu schaffen, die Ihr Geschäft vorantreibt.
                                    </p>
                                </div>
                            </div>
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Master Data Management</h2>
                                    <p className="text-md font-medium">
                                        Wir sind spezialisiert auf die Pflege und regelmäßige Aktualisierung von Artikeldaten im Rahmen unseres Master Data Managements. Unsere Experten sorgen dafür, dass Ihr Artikelstamm stets korrekt und aktuell ist. Durch gezielte Datenpflege und -aktualisierung stellen wir sicher, dass Ihre Produktinformationen immer genau sind und eine zuverlässige Grundlage für Ihren Geschäftserfolg bilden.
                                    </p>
                                </div>
                            </div>  

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Serververwaltung und FTP-Bereitstellung</h2>
                                <p className="text-md font-medium">
                                    Wir bieten umfassende Serververwaltungsdienste, einschließlich der Bereitstellung von FTP-Servern und der Verwaltung Ihrer Serverstrukturen. Unsere Experten sorgen dafür, dass Ihre Serverinfrastruktur stabil, sicher und effizient läuft. Von der Einrichtung neuer Server bis zur regelmäßigen Wartung und Optimierung sind wir Ihr zuverlässiger Partner für eine reibungslose IT-Infrastruktur. Verlassen Sie sich auf uns, um Ihren technologischen Bedarf zu decken und Ihre Geschäftsprozesse zu unterstützen.
                                </p>
                                </div>
                            </div>   
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img5} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Automatisierte Skriptabfragen (Cronjobs)</h2>
                                    <p className="text-md font-medium">
                                        Unsere Dienstleistungen umfassen die Entwicklung und Implementierung automatisierter Skriptabfragen, auch bekannt als Cronjobs, die in verschiedenen Intervallen auf unseren Systemen ausgeführt werden. Mit dieser Technologie können wir wiederkehrende Aufgaben automatisieren, wie z.B. Datenbankbereinigung, Dateiübertragungen oder regelmäßige Berichterstellung. Durch die effiziente Planung und Ausführung von Cronjobs gewährleisten wir eine reibungslose und zuverlässige Ausführung Ihrer Geschäftsprozesse, was zu einer erhöhten Effizienz und Produktivität führt.
                                    </p>
                                </div>
                            </div>              
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;