import React from 'react';

const Ecommerce = () => {
    return (
        <div id="ecommerce" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
            <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">E-Commerce mit Shopify</h2>
            <div className='flex justify-center'>
                <div className='w-24 border-b-4 border-blue-900'></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900"></h2>
            <p className="mx-12 text-center text-lg text-gray-700">Wir verwenden Shopify als E-Commerce-Plattform, weil sie eine Reihe von Vorteilen für unsere Kunden bietet. Mit Shopify können wir schnell und effizient maßgeschneiderte Online-Shops erstellen, die den individuellen Anforderungen und Bedürfnissen unserer Kunden entsprechen. Dank der integrierten Zahlungsgateways ist die Abwicklung von Transaktionen einfach und sicher, ohne dass zusätzliche Hosting- oder Zahlungsprozessor-Konfigurationen erforderlich sind.</p>
        </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Shopify Shop-Erstellung</h2>
                            <p>Wir erstellen maßgeschneiderte Shopify-Shops, die genau auf Ihre Geschäftsanforderungen zugeschnitten sind. Dazu gehören die Auswahl des Themes, die Einrichtung des Produktkatalogs, die Integration von Zahlungsgateways und mehr.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Shopify-Optimierung</h2>
                            <p>Wir verbessern die Leistung Ihres Shopify-Shops, indem wir Probleme identifizieren und beheben, die die Ladegeschwindigkeit, Benutzererfahrung oder das Suchmaschinen-Ranking beeinträchtigen könnten.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">SEO für Shopify</h2>
                            <p>Unsere SEO-Experten optimieren Ihren Shopify-Shop für Suchmaschinen, um die Sichtbarkeit zu erhöhen, den Traffic zu steigern und letztendlich mehr Verkäufe zu erzielen.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Shopify-Training</h2>
                            <p>Unsere Schulungen helfen Ihnen, die Funktionen und Möglichkeiten von Shopify voll auszuschöpfen, von den Grundlagen bis hin zu fortgeschrittenen Themen.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Shopify-Support und Wartung</h2>
                            <p>Wir bieten laufenden Support und Wartung für Ihren Shopify-Shop, um sicherzustellen, dass er sicher, aktuell und leistungsfähig bleibt.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Shopify-App-Entwicklung</h2>
                            <p>Unsere Entwickler können benutzerdefinierte Apps erstellen, um Ihrem Shopify-Shop zusätzliche Funktionen hinzuzufügen und ihn noch leistungsfähiger zu machen.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Migration zu Shopify</h2>
                            <p>Wenn Sie von einer anderen E-Commerce-Plattform zu Shopify wechseln möchten, führen wir den Migrationsprozess für Sie durch, um einen reibungslosen Übergang ohne Datenverlust sicherzustellen.</p>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <h2 className="font-semibold my-4 text-2xl text-center">Conversion-Rate-Optimierung (CRO)</h2>
                            <p>Wir verwenden datenbasierte Strategien, um die Conversion-Rate Ihres Shopify-Shops zu verbessern und Ihre Verkäufe zu steigern.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Ecommerce;