import React from 'react';
import { Link } from 'react-router-dom';

function Impressum() {
  return (
    <div className='flex flex-row justify-between items-center py-2'>
        <div className='w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl'>
        <h2>Impressum</h2>
        <p>Dieses Impressum gilt für alle Angebote unter der Domain erostech.de inklusive aller Subdomains (Unterseiten).</p>
      <p>
        Angaben gemäß § 5 TMG <br />
        Torben Wöhler<br />
        IT Consulting<br />
        Grasbruchweg 13<br />
        30938 Burgwedel<br />
        Deutschland<br />
        E-Mail: info@erostech.de<br />
        Umsatzsteuer-Identifikationsnummer: DE327955198 <br />
        Kleinunternehmer gemäß § 19 UStG <br />
      </p>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
      </p>
      <h3>Haftung für Inhalte</h3>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h3>Haftung für Links</h3>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      </p>
      <Link to="/" className="">
        Zurück zur Startseite
      </Link>
    </div>
    </div>
  );
}

export default Impressum;