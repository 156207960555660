import React from 'react';

const Data = () => {
    const exampleProduct = {
        name: "T-Shirt EROS",
        Variante: "M",
        price: "19,99 €",
        availability: "Auf Lager",
        ean: "1234567890"
    };
    const exampleProduct2 = {
        name: "T-Shirt EROS",
        Variante: "S",
        price: "19,99 €",
        availability: "Auf Lager",
        ean: "1235667890"
    };
    return (
    <div id="data" className="bg-gray-100 py-12">
    <section data-aos="zoom-in-down">
            <div className="container mx-auto">
                <div className="max-w-4xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Stammdatenpflege auf höchstem Niveau</h2>
                    <h2 className="text-2xl font-semibold text-center text-gray-700 mb-4">Ihre Daten in sicheren Händen</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        Master Data Management (MDM) ist ein entscheidender Prozess für Unternehmen, um die Qualität und Konsistenz ihrer Daten sicherzustellen. Bei ErosTech Solutions übernehmen wir die Verantwortung für die Pflege Ihrer Daten und die Kommunikation mit externen Dienstleistern, um sicherzustellen, dass Ihre Daten stets genau, aktuell und zuverlässig sind.
                    </p>
                    <p className="text-lg text-gray-600 leading-relaxed mb-8">Die Pflege des Artikelstamms ist eine der herausforderndsten Aufgaben für viele Unternehmen. Ein fehlerhafter oder unvollständiger Artikelstamm kann zu Problemen wie falschen Bestellungen, Lieferverzögerungen und ungenauen Lagerbeständen führen. Wir verstehen die Komplexität und die Anforderungen, die mit der Verwaltung von Artikeldaten einhergehen. Unser Team von Experten steht Ihnen zur Seite, um sicherzustellen, dass Ihr Artikelstamm stets aktuell, korrekt und vollständig ist. Wir bieten maßgeschneiderte Lösungen an, um Ihre Artikelstammdaten effizient zu verwalten und Ihnen dabei zu helfen, Ihre Geschäftsprozesse zu optimieren.</p>
                    <p className="text-lg text-gray-700 mb-6">
                        Unser Team von Experten arbeitet engagiert daran, Ihre Daten ordnungsgemäß zu verwalten und sicherzustellen, dass sie stets den höchsten Standards entsprechen. Wir verstehen, dass die Qualität Ihrer Daten von entscheidender Bedeutung ist, um fundierte Geschäftsentscheidungen zu treffen und den reibungslosen Ablauf Ihrer Unternehmensprozesse zu gewährleisten.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        Durch unsere umfassende Erfahrung im Bereich des Master Data Managements können Sie sich darauf verlassen, dass wir Ihre Daten sorgfältig pflegen und sicherstellen, dass sie stets korrekt und konsistent sind. Wir übernehmen die Aufgabe, Ihre Dateien gemäß den geltenden Vorschriften und Best Practices aufzubewahren, um sicherzustellen, dass Ihre Daten jederzeit sicher und geschützt sind.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        Darüber hinaus gehen wir über die reine Verwaltung Ihrer Daten hinaus, indem wir enge Beziehungen zu externen Dienstleistern pflegen und die Kommunikation mit ihnen koordinieren. Auf diese Weise stellen wir sicher, dass Ihre Daten nahtlos mit anderen Systemen und Partnern integriert werden können, um einen reibungslosen Informationsaustausch zu gewährleisten.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        Mit unserer eigenen Software haben wir einen Standard definiert, um einen reibungslosen Austausch mit anderen Artikeldateiformaten wie .csv, .xml usw. zu gewährleisten. Dadurch können Sie sicher sein, dass Ihre Daten problemlos zwischen verschiedenen Plattformen und Systemen übertragen werden können, ohne dass es zu Kompatibilitätsproblemen kommt.
                    </p>
                    <br></br>
                    <div className="mt-6">
                    <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Produkt</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preis</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Verfügbarkeit</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">EAN</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Standort</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">T-Shirt</td>
                                    <td className="px-6 py-4 whitespace-nowrap">19,99 €</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Auf Lager</td>
                                    <td className="px-6 py-4 whitespace-nowrap">2155678901</td>
                                    <td className="px-6 py-4 whitespace-nowrap">VK-Fläche</td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="px-6 py-4 whitespace-nowrap">Jeans</td>
                                    <td className="px-6 py-4 whitespace-nowrap">39,99 €</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Auf Lager</td>
                                    <td className="px-6 py-4 whitespace-nowrap">125678901</td>
                                    <td className="px-6 py-4 whitespace-nowrap">VK-Fläche</td>
                                </tr>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">Turnschuhe</td>
                                    <td className="px-6 py-4 whitespace-nowrap">29,99 €</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Auf Lager</td>
                                    <td className="px-6 py-4 whitespace-nowrap">3496089013</td>
                                    <td className="px-6 py-4 whitespace-nowrap">VK-Fläche</td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="px-6 py-4 whitespace-nowrap">Kaffeemaschine</td>
                                    <td className="px-6 py-4 whitespace-nowrap">79,99 €</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Auf Lager</td>
                                    <td className="px-6 py-4 whitespace-nowrap">4567890123</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Lager 4</td>
                                </tr>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">Buch</td>
                                    <td className="px-6 py-4 whitespace-nowrap">9,99 €</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Auf Lager</td>
                                    <td className="px-6 py-4 whitespace-nowrap">5678901234</td>
                                    <td className="px-6 py-4 whitespace-nowrap">Lager 3</td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <p>
                           Wir bieten unseren Dienstleistern die Dateien entweder in fester Form oder über unsere API an, um eine reibungslose Integration und Datenkommunikation zu gewährleisten.
                        </p>
                        <br></br>
                       <h5> <b>Bekommen Sie Ihre Dateien in einem ordnungsgemäßen Format </b> </h5>
                       <br></br>
                       <p id="data-format">JSON-Datei </p>
                        <pre className="bg-gray-100 p-4 rounded-lg">
                            
                            JSON: {JSON.stringify(exampleProduct, null, 2)}
                        </pre>
                        <p id="data-format">XML-Rückgabe </p>
                        <pre className="bg-gray-100 p-4 rounded-lg">
                        XML: &lt;product&gt;
                        &lt;name&gt;T-Shirt EROS&lt;/name&gt;
                        &lt;Variante&gt;S&lt;/Variante&gt;
                        &lt;price&gt;19,99 €&lt;/price&gt;
                        &lt;availability&gt;Auf Lager&lt;/availability&gt;
                        &lt;ean&gt;1235667890&lt;/ean&gt;
                        &lt;/product&gt;
                        </pre>
                        <br></br>
                        <p> Wir verstehen die Komplexität der Systemintegration und sorgen dafür, dass die Schnittstellen nahtlos funktionieren, um einen reibungslosen Datenaustausch zu gewährleisten. Durch unsere Expertise stellen wir sicher, dass die Datenströme zwischen den ERP-Systemen reibungslos verlaufen und die Geschäftsprozesse unserer Kunden unterstützen.</p>
                </div>
                </div>
            </div>
        </section>
    </div>
    );
}

export default Data;