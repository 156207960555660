import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
<div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={img} />
        </div>
        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
            <h3 className="text-3xl text-blue-900 font-bold">Maßgeschneiderte Web-, Schnittstellen- und Mobile-Anwendungen</h3>
            <div>
                <p className='my-3 text-xl text-gray-600 font-semibold'>Als IT-Unternehmen aus der Region Hannover konzentrieren wir uns darauf, unsere Kunden in den Bereichen Master Data Management (Pflege des Artikelstammes), ERP-Systeme, API (Schnittstellenanbindungen), Webentwicklung, E-Commerce sowie individuelle Softwarelösungen zu unterstützen.</p>
            </div>
            <div>
                <p className='my-3 text-xl text-gray-600 font-semibold'>Als erfahrener Partner im Bereich Food, Non-Food und DIY stehen wir kleinen und mittelständischen Unternehmen zur Seite. Mit fundiertem Know-how und branchenspezifischem Fachwissen bieten wir maßgeschneiderte Lösungen, die Ihre Anforderungen optimal erfüllen.</p>
            </div>
            <div>
                <p className='my-3 text-xl text-gray-600 font-semibold'>Wir freuen uns darauf, von Ihnen zu hören. Durch ein kostenloses Erstgespräch können wir gemeinsam herausfinden, wie wir Ihnen am besten helfen können.</p>
            </div>
            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                Kontaktieren Sie uns    
                <svg className="w-4 h-4 ml-1 group-hover:translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
            </Link>
        </div>
    </div>
</div>
        </>
    )
}

export default Intro;